import React from "react"
import "./styles.scss"
const Feedback = () => {
	return (
		<>
			<div className="col-sm-12   " style={{ margin: "auto", borderRadius: "10px", height: "260px" }}>
				<div className="row">
					<div className=" d-flex justify-content-center align-items-center mb-4 mb-lg-0">
						<img
							style={{ margin: "auto", padding: "10px" }}
							src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20%2810%29.webp"
							className="rounded-circle img-fluid shadow-1"
							alt="woman avatar"
							width="110"
							height="110"
						/>
					</div>
					<p className="fw-bold lead  " style={{ textAlign: "center", fontSize: "2rem" }}>
						Adriana
					</p>
					<p style={{ textAlign: "center", fontSize: "1.7rem" }} className=" feedbackDetail mb-4">
						Write Your detail about FeedBack here card.
					</p>
				</div>
			</div>
		</>
	)
}
export default Feedback
