import React, { useEffect, useState } from "react"
import * as styles from "./styles.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Col } from "react-bootstrap"
import { navigate } from "gatsby"
import AOS from "aos"
import Feedback from "../FeedBack"

const ProjectDescription = (props) => {
	if (typeof window !== "undefined") {
		window.addEventListener("load", AOS.refresh())
		AOS.init({
			once: true,
			mirror: true,
		})
	}
	const [backgroundColor, setBackgroundColor] = useState()
	useEffect(() => {
		if (typeof window !== "undefined") {
			setBackgroundColor(localStorage.getItem("selectColor"))
		}
	})

	return (
		<>
			<div className={`${styles.pdMain} mt-5 pt-5`}>
				<div className={styles.bussinesSec} style={{ background: backgroundColor }}>
					<div>
						<div className={styles.backdiv}>
							<button
								onClick={() => {
									if (typeof window !== "undefined") {
										localStorage.setItem("activePage", 0)
									}
									navigate("/projects")
								}}
								size="sm"
								className={styles.backbutton}
								variant="primary"
								type="submit"
							>
								<svg
									className={styles.backerrow}
									xmlns="http://www.w3.org/2000/svg"
									shapeRendering="geometricPrecision"
									textRendering="geometricPrecision"
									imageRendering="optimizeQuality"
									width="17px"
									height="20px"
									clipRule="evenodd"
									viewBox="0 0 512 404.43"
								>
									<path
										d="m68.69 184.48 443.31.55v34.98l-438.96-.54 173.67 159.15-23.6 25.79L0 199.94 218.6.02l23.6 25.79z"
										fill="white"
									/>
								</svg>
								Go Back
							</button>
						</div>

						<h5 className={styles.mainTxt}>BUSINESS</h5>
						<h1 className={styles.busHead}>Choosee</h1>
						<h5 className={styles.busHeadTxt}>Discover all brands</h5>
						<div className={`my-5 ${styles.techTags}`}>
							<div className={styles.techTag}>
								<span className={styles.techTagTxt}>Database</span>
							</div>
							<div className={styles.techTag}>
								<span className={styles.techTagTxt}>Mobile</span>
							</div>
							<div className={styles.techTag}>
								<span className={styles.techTagTxt}>Backend</span>
							</div>
						</div>
						<div
							data-aos="fade-up"
							data-aos-once="true"
							data-aos-offset="100"
							data-aos-easing="ease-in-out"
							data-aos-duration="1500"
							data-aos-mirror="true"
							className="pb-4 d-flex justify-content-center mx-auto setPicWidth"
							style={{ width: "40%" }}
						>
							<StaticImage
								className={styles.hoverTransition}
								src="../../../assets/images/mobileApp.png"
								alt="Website IT Company"
								placeholder="none"
								height={400}
							/>
						</div>
					</div>
				</div>
				<div className={styles.oppurtunitySec}>
					<Row className="m-0">
						<Col lg={5} md={6} className={`p-0 d-flex justify-content-center ${styles.imageHeight}`}>
							<div className={`${styles.imgPadding} ""`} style={{ display: "flex" }}>
								<StaticImage
									src="../../../assets/images/mobile-4.png"
									alt="Website IT Company"
									placeholder="none"
								></StaticImage>
								<StaticImage
									src="../../../assets/images/mobile-4.png"
									alt="Website Design Company"
									placeholder="Website Development Company"
								></StaticImage>
							</div>
						</Col>
						<Col lg={7} md={6} className="p-0 d-flex">
							<div className="mx-5">
								<h2 className={`${styles.opportunity} "mb-4 "`}>Opportunity</h2>
								<div className="me-lg-5 me-0 text-justify mt-5">
									<p className={styles.projectDetail}>
										Lorem ipsum dolor sit amet. Sit velit natus et omnis recusandae ut dolore voluptatibus rem nisi
										minus id quia sunt est repudiandae culpa. Sed Quis omnis vel voluptate unde et quas autem et
										possimus voluptatem. Ea dolore voluptatem aut voluptas iure qui quos molestiae quo beatae aliquam
										qui quidem doloremque. Lorem ipsum dolor sit amet. Sit velit natus et omnis recusandae ut dolore
										voluptatibus rem nisi minus id quia sunt est repudiandae culpa. Sed Quis omnis vel voluptate unde et
										quas autem et possimus voluptatem. Ea dolore voluptatem aut voluptas iure qui quos molestiae quo
										beatae aliquam qui quidem doloremque.
									</p>
								</div>
							</div>
						</Col>
					</Row>
					<div style={{ paddingTop: "60px" }} className={`mx-lg-4 mx-5 ${styles.approach}`}>
						<h2 className={`${styles.opportunity} "mb-4 ml-5"`} style={{ width: "90%", margin: "auto" }}>
							Approach
						</h2>
						<div>
							<p className={styles.approachPara}>
								Lorem ipsum dolor sit amet. Sit velit natus et omnis recusandae ut dolore voluptatibus rem nisi minus id
								quia sunt est repudiandae culpa. Sed Quis omnis vel voluptate unde et quas autem et possimus voluptatem.
								Ea dolore voluptatem aut voluptas iure qui quos molestiae quo beatae aliquam qui quidem doloremque. Sit
								doloremque internos aut quas ipsam vel voluptatem voluptatibus est rerum itaque. At asperiores unde quo
								temporibus deserunt in nesciunt atque ut porro enim eos voluptate facilis quo facere odio aut accusamus
								laudantium.
							</p>
						</div>
					</div>
				</div>
				<div className={`py-5 ${styles.solutionSec}`}>
					<Row className="m-0">
						<Col md={7} className="p-0 d-flex align-items-center">
							<div className={` "mx-5 "`} style={{ margin: "0 20px 60px 40px" }}>
								<h2 className={`${styles.opportunity} "mb-4"`} style={{ width: "90%", margin: "auto" }}>
									Solution
								</h2>
								<div className={styles.approachPara}>
									<p style={{ fontSize: "20px", textAlign: "justify" }}>
										Lorem ipsum dolor sit amet. Sit velit natus et omnis recusandae ut dolore voluptatibus rem nisi
										minus id quia sunt est repudiandae culpa. Sed Quis omnis vel voluptate unde et quas autem et
										possimus voluptatem. Ea dolore voluptatem aut voluptas iure qui quos molestiae quo beatae aliquam
										qui quidem doloremque. Lorem ipsum dolor sit amet. Sit velit natus et omnis recusandae ut dolore
										voluptatibus rem nisi minus id quia sunt est repudiandae culpa. Sed Quis omnis vel voluptate unde et
										quas autem et possimus voluptatem. Ea dolore voluptatem aut voluptas iure qui quos molestiae quo
										beatae aliquam qui quidem doloremque.
									</p>
								</div>
							</div>
						</Col>
						<Col md={5} className={`p-0 d-flex justify-content-center ${styles.imageHeight}`}>
							<div className={styles.imgPadding} style={{ display: "flex" }}>
								<StaticImage
									src="../../../assets/images/mobile-4.png"
									alt="Website IT Company"
									placeholder="none"
								></StaticImage>
								<StaticImage
									src="../../../assets/images/mobile-4.png"
									alt="Website Design Company"
									placeholder="none"
								></StaticImage>
							</div>
						</Col>
					</Row>
				</div>
				<div className="p-5">
					<h2 className={`${styles.opportunity} "mb-5"`} style={{ width: "90%", margin: "auto" }}>
						Feedback
					</h2>
					<Feedback />
				</div>
			</div>
		</>
	)
}
export default ProjectDescription
