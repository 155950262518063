// extracted by mini-css-extract-plugin
export var approachPara = "styles-module--approachPara--UBHM3";
export var backToProjects = "styles-module--backToProjects--nHVPd";
export var backbutton = "styles-module--backbutton--03T4g";
export var backdiv = "styles-module--backdiv--PM29x";
export var backerrow = "styles-module--backerrow--FpdwJ";
export var busHead = "styles-module--busHead--DLFCV";
export var busHeadTxt = "styles-module--busHeadTxt--yxmWr";
export var bussinesSec = "styles-module--bussinesSec--0s1zi";
export var imageHeight = "styles-module--imageHeight--qEh8o";
export var imgPadding = "styles-module--imgPadding--fngJc";
export var mainTxt = "styles-module--mainTxt--dEz78";
export var opportunity = "styles-module--opportunity--g14G3";
export var oppurtunitySec = "styles-module--oppurtunitySec--cWRx3";
export var pdMain = "styles-module--pdMain--mMsah";
export var primary = "\"abc\"";
export var projectDetail = "styles-module--projectDetail--DPl4t";
export var secondary = "\"dec\"";
export var solutionSec = "styles-module--solutionSec---oEQ+";
export var techTag = "styles-module--techTag--QtvwZ";
export var techTags = "styles-module--techTags--5CkX5";