import React from "react"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import ProjectDescription from "components/pages/ProjectDescription"
import { Helmet } from "react-helmet"

const ProjectNamepage = () => {
	return (
		<>
			<Helmet>
				<title>MercurySols Technologies | Project Description</title>
			</Helmet>
			<HeaderTwo />
			<ProjectDescription />
			<Footer />
		</>
	)
}

export default ProjectNamepage
